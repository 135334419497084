/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'D-DIN';
  font-style: normal;
  font-weight: normal;
  src: url('D-DIN.woff') format('woff');
}

/*
@font-face {
  font-family: 'D-DIN';
  font-style: italic;
  font-weight: normal;
  src: url('D-DIN-Italic.woff') format('woff');
}


@font-face {
  font-family: 'D-DIN';
  font-style: normal;
  font-weight: bold;
  src: url('D-DIN-Bold.woff') format('woff');
}


@font-face {
  font-family: 'D-DIN Condensed';
  font-style: normal;
  font-weight: normal;
  src: url('D-DINCondensed.woff') format('woff');
}


@font-face {
  font-family: 'D-DIN Exp';
  font-style: normal;
  font-weight: normal;
  src: url('D-DINExp.woff') format('woff');
}


@font-face {
  font-family: 'D-DIN Exp';
  font-style: italic;
  font-weight: normal;
  src: url('D-DINExp-Italic.woff') format('woff');
}


@font-face {
  font-family: 'D-DIN Condensed';
  font-style: normal;
  font-weight: bold;
  src: url('D-DINCondensed-Bold.woff') format('woff');
}


@font-face {
  font-family: 'D-DIN Exp';
  font-style: normal;
  font-weight: bold;
  src: url('D-DINExp-Bold.woff') format('woff');
}
*/
