html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow: hidden !important;
}
html,
body {
  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
}
#root {
  height: 100%;
  width: 100%;
  overflow: hidden !important;
}

.no-scrollbar {
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
  color: transparent;
}

.global-draggable {
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
}
