/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100 900;
  src: url('Montserrat-VariableFont_wght.ttf') format('truetype');
}
    

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 100 900;
  src: url('Montserrat-Italic-VariableFont_wght.ttf') format('truetype');
}