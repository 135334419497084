@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .filter-none {
    filter: none;
  }
  .no-scrollbar {
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
    color: transparent;
  }
}
